<template>
  <div>
    <b-table
      :items="exames"
      :fields="campos"
      responsive="sm"
      stacked="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      show-empty
    >
     <template #empty>
                    <div class="text-center text-muted d-flex flex-column p-2">
                      <div class="justify-content-center py-2">
                        <img
                          width="150px"
                          height="125px"
                          :src="
                            require('@/assets/img/undraw_No_data_re_kwbl.svg')
                          "
                          alt=""
                        />
                        <p class="mt-2">
                          Nada aqui...
                        </p>
                      </div>
                    </div>
                  </template>
      <template #table-busy class="text-center">
        <b-spinner />
      </template>
      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button size="sm" variant="danger" @click="excluir(row.item)"
          ><i class="fa fa-trash"></i> Excluir</b-button
        >
      </template>
      <template #cell(valor_venda)="row">
        {{ row.item.valor_venda | currencyWithMask }}
      </template>

      <template #cell(valor_custo)="row">
        {{ row.item.valor_custo | currencyWithMask }}
      </template>
    </b-table>
    <b-pagination
      @input="$emit('changePage', $event)"
      :value="pagination.pagina_atual"
      :total-rows="pagination.total_registros"
      :per-page="pagination.registros_por_pagina"
    >
    </b-pagination
    ><b-table> </b-table>
  </div>
</template>

<script>
export default {
  props: {
    filtro: String,
    exames: Array,
    pagination: {type: Object},
    loading: {type: Boolean, default: false}
  },
  data() {
    return {
      lista: [],
       paginacao:  {
        total_paginas: 0,
        pagina_atual: 1,
        total_registros: 0,
        registros_por_pagina: 0,
      },
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "tipo", label: "Tipo", sortable: true },
        { key: "valor_venda", label: "Valor Venda", sortable: true },
        { key: "valor_custo", label: "Valor Custo", sortable: true },
        { key: "actions", label: "" },
      ],
     
    };
  },
  created(){this.paginacao = Object.assign(this.paginacao, this.pagination)},
  methods: {
    editar(exame) {
      this.$emit("editar", exame);
    },
    excluir(exame) {
      this.$emit("excluir", exame);
    },
  },

};
</script>

<style></style>
