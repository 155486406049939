<template>
  <div>
    <BarraCrud :botaoNovo="true" :onNovo="novo" :onFiltrar="filtrar" />

    <ExamesTable

      :exames="exames"
      @editar="editar($event)"
      @excluir="excluir($event)"
      :pagination="paginacao"
      @changePage="paginacao.pagina_atual = $event"
      :loading="loading"
    />

    <b-modal
      id="modal-exames"
      no-fade
      hide-footer
      no-enforce-focus
      content-class="modalDireito"
    >
      <template #modal-title>
        <i class="fas fa-syringe"></i>
        {{ typeof exame_edit.id === "number" ? "Editar" : "Adicionar" }}
        Produto/Serviço/Exame
      </template>
      <ExamesForm :editObj="exame_edit" @salvou="carregar()" @close="close()" />
    </b-modal>
  </div>
</template>

<script>
import ExamesLib from "../../libs/ExamesLib";
import BarraCrud from "@/components/common/BarraCrud";
import ExamesTable from "@/components/Exames/ExamesTable.vue";
import ExamesForm from "@/components/Exames/ExamesForm.vue";

export default {
  components: {
    BarraCrud,
    ExamesTable,
    ExamesForm,
  },
  data() {
    return {
      filtro: "",
      exames: [],
      exame_edit: {},
      loading: false,
      paginacao: {
        total_paginas: 0,
        pagina_atual: 1,
        total_registros: 0,
        registros_por_pagina: 10,
      },
    };
  },
  methods: {
    limpar() {
      for (const key in this.exame_edit) {
        this.exame_edit[key] = "";
      }
    },
    novo() {
      this.limpar();
      this.$bvModal.show("modal-exames");
    },
    filtrar(filtro) {
      this.filtro = filtro;
      this.carregar()
    },
    close() {
      this.$bvModal.hide("modal-exames");
    },
    async carregar() {
      this.loading = true
      try {
        let result = await ExamesLib.get(
          null,
          null,
          null,
          this.filtro,
          this.paginacao
        );
        this.exames = result.result;
        this.paginacao = result.pagination;
      } catch (error) {
        console.log(error);
        this.showToast("error", error);
      }
            this.loading = false
    },
    async excluir(exame) {
      this.$swal
        .fire({
          title: `Você tem certeza que deseja excluir este exame?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim. Excluir!",
          cancelButtonText: "Não.",
        })
        .then(async (res) => {
          if (res.isConfirmed) {
            try {
              // console.log('exame excluir',exame.id)
              const result = await ExamesLib.del(exame.id);
              // console.log(result);
              if (result && result.status === 200) {
                this.$swal.fire({
                  title: `Exame excluído com sucesso!`,
                  icon: "success",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
                await this.carregar();
              }
            } catch (error) {
              this.showToast(
                "error",
                `Ops! Algo deu errado :/\n${error.message}`
              );
            }
          }
        });
    },

    editar(exame) {
      Object.assign(this.exame_edit, exame);
      // this.exame_edit.valor_custo = this.formatFloatToReal(
      //   this.exame_edit.valor_custo
      // );
      // this.exame_edit.valor_venda = this.formatFloatToReal(
      //   this.exame_edit.valor_venda
      // );
      // console.log('editando exame', this.exame_edit)
      this.$forceUpdate();
      //   console.log(exame)
      this.$bvModal.show("modal-exames");
    },
  },
  mounted() {
    this.carregar();
  },
  watch: {
    'paginacao.pagina_atual': {
      
      async handler(){
        await this.carregar()
      }
    }
  }
};
</script>

<style></style>
